export default defineNuxtPlugin(nuxtApp => {
  nuxtApp.hook("vuetify:configuration", vuetify => {
    vuetify.vuetifyOptions = vuetify.vuetifyOptions || {};
    vuetify.moduleOptions = vuetify.moduleOptions || { styles: { configFile: "./styles/variables.scss" } };
    Object.assign(vuetify.vuetifyOptions, {
      theme: {
        defaultTheme: "dark",
        themes: {
          dark: {
            dark: false,
            colors: {
              background: "#000",
              surface: "#008c64",
              rating: "#f4c74d",
              cardTitle: "#6a040f", // "#ffeeba",
              linkColor: "#02ff99",
              jackpot: "#007bff",
              iconColor: "#008c64",
              toTop: "#008c64",
            },
          },
          light: {},
        },
      },
    });
    vuetify.vuetifyOptions.icons.aliases.ratingFull = vuetify.vuetifyOptions.icons.aliases.cardsSpade;
    vuetify.vuetifyOptions.icons.aliases.ratingEmpty = vuetify.vuetifyOptions.icons.aliases.cardsSpadeOutline;
  });
});
